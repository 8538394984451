import * as React from "react";

import ArticleCard from "./article-card";
import En from "../../translations/en.yaml";
import It from "../../translations/it.yaml";

import { useStaticQuery, graphql } from "gatsby";

export default function IndexCover({ language }) {
  var data = useStaticQuery(graphql`
    query latestArticlesIt {
      allItYaml(sort: { fields: date, order: DESC }) {
        nodes {
          category
          author
          cover {
            childImageSharp {
              gatsbyImageData(width: 420)
            }
          }
          coveralt
          date(formatString: "DD MMMM YYYY")
          description
          h1
          link
          version
        }
      }
    }
  `);
  const articles = data.allItYaml.nodes;

  var lang = language == "en" ? En : language == "it" ? It : En;

  return (
    <section className="category-section">
      <div className="section-title">News</div>
      <div className="columns-w">
        <div className="column">
          <ArticleCard
            type={"noimage"}
            author={articles[0].author}
            date={articles[0].date}
            title={articles[0].h1}
            category={articles[0].category}
            description={articles[0].description}
            link={articles[0].link}
            version={articles[0].version}
            alt={articles[0].coveralt}
            language={language}
          />
          <ArticleCard
            date={articles[1].date}
            author={articles[1].author}
            img={articles[1].cover}
            title={articles[1].h1}
            category={articles[1].category}
            description={articles[1].description}
            link={articles[1].link}
            version={articles[1].version}
            alt={articles[1].coveralt}
            language={language}
          />
          <ArticleCard
            type={"noimage"}
            date={articles[4].date}
            author={articles[4].author}
            title={articles[4].h1}
            category={articles[4].category}
            description={articles[4].description}
            link={articles[4].link}
            version={articles[4].version}
            alt={articles[4].coveralt}
            language={language}
          />
        </div>
        <div className="column-small">
          <ArticleCard
            date={articles[2].date}
            author={articles[2].author}
            title={articles[2].h1}
            img={articles[2].cover}
            category={articles[2].category}
            description={articles[2].description}
            link={articles[2].link}
            version={articles[2].version}
            alt={articles[2].coveralt}
            language={language}
          />
          <ArticleCard
            date={articles[3].date}
            author={articles[3].author}
            title={articles[3].h1}
            img={articles[3].cover}
            category={articles[3].category}
            description={articles[3].description}
            link={articles[3].link}
            version={articles[3].version}
            alt={articles[3].coveralt}
            language={language}
          />
        </div>
        <div className="column-small">
          <div
            variant="categories"
            style={{ marginBottom: 50 }}
            className="category-menu"
          >
            <div className="category-menu-title">{lang["categories"]}</div>
            <div className="category-menu-item">
              <a href={language == "it" ? "arte.php" : "art.php"}>
                <span category="Art">•</span> {lang["Art"].toUpperCase()}
              </a>
            </div>
            <div className="category-menu-divider"></div>
            <div className="category-menu-item">
              <a href={language == "it" ? "letteratura.php" : "literature.php"}>
                <span category="Literature">•</span>{" "}
                {lang["Literature"].toUpperCase()}
              </a>
            </div>
            <div className="category-menu-divider"></div>
            <div className="category-menu-item">
              <a href={language == "it" ? "musica.php" : "music.php"}>
                <span category="Music">•</span> {lang["Music"].toUpperCase()}
              </a>
            </div>
            <div className="category-menu-divider"></div>
            <div className="category-menu-item">
              <a href={language == "it" ? "storia.php" : "history.php"}>
                <span category="History">•</span>{" "}
                {lang["History"].toUpperCase()}
              </a>
            </div>
          </div>
          {language === "it" ? (
            <div variant="trending" className="category-menu">
              <div className="category-menu-title">Trending on ilSalice</div>
              <div className="category-menu-item">
                <div className="category-menu-item-number">1</div>
                <div>
                  <a href="https://www.ilsalice.org/it/la-musica-secondo-schopenhauer-398">
                    <div className="category-menu-author">
                      <span></span>Roberta Gennuso
                    </div>
                    La musica secondo Schopenhauer e Scruton
                  </a>
                </div>
              </div>
              <div className="category-menu-divider"></div>
              <div className="category-menu-item">
                <div className="category-menu-item-number">2</div>
                <div>
                  <a href="https://www.ilsalice.org/https://www.ilsalice.org/it/il-terzo-reich-una-401">
                    <div className="category-menu-author">
                      <span></span>Marco Zagni
                    </div>
                    Il Terzo Reich, una distopia musicale
                  </a>
                </div>
              </div>
              <div className="category-menu-divider"></div>
              <div className="category-menu-item">
                <div className="category-menu-item-number">3</div>
                <div>
                  <a href="https://www.ilsalice.org/it/tornare-a-cercare-lignoto-399">
                    <div className="category-menu-author">
                      <span></span>Stefano Vivaldini
                    </div>
                    Tornare a cercare l'ignoto
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <div variant="trending" className="category-menu">
              <div className="category-menu-title">Trending on ilSalice</div>
              <div className="category-menu-item">
                <div className="category-menu-item-number">1</div>
                <div>
                  <a href="https://www.ilsalice.org/music-according-to-schopenhauer-403">
                    <div className="category-menu-author">
                      <span></span>Roberta Gennuso
                    </div>
                    Music according to Schopenhauer and Scruton
                  </a>
                </div>
              </div>
              <div className="category-menu-divider"></div>
              <div className="category-menu-item">
                <div className="category-menu-item-number">2</div>
                <div>
                  <a href="https://www.ilsalice.org/isnt-being-a-dancer-also-being-an-athlete-336">
                    <div className="category-menu-author">
                      <span></span>Vittoria Silluzio
                    </div>
                    Isn't being a dancer also being an athlete?
                  </a>
                </div>
              </div>
              <div className="category-menu-divider"></div>
              <div className="category-menu-item">
                <div className="category-menu-item-number">3</div>
                <div>
                  <a href="https://www.ilsalice.org/i-learned-to-say-thank-you-302">
                    <div className="category-menu-author">
                      <span></span>Cristina Mura
                    </div>
                    I learned to say thank you
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
